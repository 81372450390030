import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_ADMIN_URL = `${API_URL}v1/auth/admin/`
export const GET_SEARCH_ADMIN_URL = `${API_URL}v1/auth/filter-admin/`
export const CHANGE_STATUS_ADMIN_URL = `${API_URL}v1/auth/admin/kyc-verification`
export const UPLOAD_KYCDOC_ADMIN_URL = `${API_URL}v1/auth/edit-admin/`

// Get Role Data
export async function getAdminData(startIndex: number, limitNumber: number) {
  try {
    let adminData = await axios.get(`${GET_ADMIN_URL}${startIndex}/${limitNumber}`)
    return adminData
  } catch (e) {
    throw e
  }
}

// Get Role Data
export async function getSearchAdminData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let adminData = await axios.post(`${GET_SEARCH_ADMIN_URL}${startIndex}/${limitNumber}`, {
      searchText,
    })
    return adminData
  } catch (e) {
    throw e
  }
}

// export async function changeStatusAdminData(values: any) {
//   try {
//     let adminData = await axios.put(`${CHANGE_STATUS_ADMIN_URL}`, {
//       adminId,
//       flag,
//     })
//     return adminData
//   } catch (e) {
//     throw e
//   }
// }

export async function changeStatusAdminData(values: any) {
  try {
    values.flag = values.flag == "true" ? true : false 

    let adminData = await axios.put(`${CHANGE_STATUS_ADMIN_URL}`, {
    ...values
    })
    return adminData
  } catch (e) {
    throw e
  }
}

export async function kycDocumentUpload(values: any, id : any) {
  try {
    let body: any = {}

    body.kyc_document = values.fileName ? values.fileName  : null 

    let adminData = await axios.post(`${UPLOAD_KYCDOC_ADMIN_URL}${id}`, {
    ...body
    })
    return adminData
  } catch (e) {
    throw e
  }
}