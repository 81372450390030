/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getAdminData} from '../redux/AdminCRUD'
import {useDispatch} from 'react-redux'
import * as admin from '../redux/AdminRedux'
import ListAdminData from './ListAdminData'

const ListPage: FC = () => {
  const dispatch = useDispatch()

  const callAdminData = async () => {
    try {
      let adminData = await getAdminData(0, 10)
      if (adminData.data) {
        await dispatch(admin.actions.setAdminLoading(true))
        // once admin data set then loading will automatically off using saga middleware
        await dispatch(admin.actions.setAdminData(adminData.data.docs))
        await dispatch(admin.actions.setAdminDataTotal(adminData.data.total))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callAdminData()
  }, [])

  return (
    <div>
      <ListAdminData />
    </div>
  )
}

const ListWraaper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/admin/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/admin/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.ADMIN'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWraaper}
